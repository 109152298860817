const environment = {
  apiUrl: "https://optimisticwebapis.azurewebsites.net/api/",
  //apiUrl: 'https://localhost:44376/api/',
  //apiUrl:'https://optimisticstagingwebapi.azurewebsites.net/api/'
  //apiUrl: "https://optmisticwebapiprd.azurewebsites.net/api/",
  //apiUrl: 'https://optimistic-api.azurewebsites.net/api/'
  
  //apiUrl: "https://api.optimistic-app.com/api/", //production

  //apiUrl: "https://optimistic-api-slot.azurewebsites.net/api/", //production-slot
};
export default environment;
